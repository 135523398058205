import {MovePositions} from "./MovePositions";
import {AnimatePositions} from "./AnimatePositions";
/**
 * Created by Jason on 6/3/2016.
 */
export class CarouselPositions 
{
    private moves : MovePositions;
    private anims : AnimatePositions;
    constructor($realCarousel : JQuery)
    {
        this.moves = new MovePositions($realCarousel);
        this.anims = new AnimatePositions($realCarousel);
    }
    public getMoves()
    {
        return this.moves;
    }
    public getAnims()
    {
        return this.anims;
    }
  
}