/**
 * Created by Jason on 5/27/2016.
 */
export class StopWatch
{
    private ourfunction :  () => void;
    private timeout : number;
    private enabled = true;
    private currentTick = 0;

    /**
     *
     * @param inputFunction
     * @param inputTimeout
     */
    constructor(inputFunction : () => void, inputTimeout : number)
    {
        this.ourfunction = inputFunction;
        this.timeout = inputTimeout;
        this.enabled = true;
        this.currentTick = 0;
        setInterval(() => this.tick(), 100);
        this.tick();
    }

    /**
     * Tick private used to handle ticks
     */
    private tick()
    {
        if(!this.enabled)
            return;
        this.currentTick+= 100;
        if(this.currentTick >= this.timeout)
            setTimeout(() => this.hitTimeout(), 0);
    }

    /**
     * Hit the timeout and will call the function
     */
    private hitTimeout()
    {
        this.ourfunction();
        this.currentTick = 0;
    }

    /**
     * pauses the ticker
     */
    public pause()
    {
        this.enabled = false;
    }

    /**
     * Plays the ticker if paused or stopped
     */
    public play()
    {
        if(!this.enabled)
            this.enabled = true;
    }

    /**
     * Stops the ticker
     */
    public stop()
    {
        this.enabled = false;
        this.currentTick = 0;
    }
}