//a simple private to click next link
//a timer will call this function, and the rotation will begin :)  
import {StopWatch} from "./StopWatch";
import {CarouselPositions} from "./CarouselPositions";
var $ = jQuery.noConflict();

export class RealCarousel
{
    private realCarousel : JQuery;
    private slides : JQuery;
    private slidesListItem : JQuery;
    private lastSlide : JQuery;
    private firstSlide : JQuery;
    private nextButton : JQuery;
    private previousButton : JQuery;
    private captionContainer : JQuery;
    private captionText : JQuery;
    private activeSlideSelector : string;
    private activeImageSelector : string;
    private leftSlideSelector : string;
    private rightSlideSelector : string;
    private moveCompleted : boolean;
    private speed = 5000;
    private carouselWidth : number;
    private carouselHeight : number;
    private item_width : number;
    private itemHeight : number;
    private activeHeight : number;
    private left_value : number;
    private leftSpot : number;
    private rightSpot : number;
    private activeWidth : number;
    private middleSpot : number;
    private stopwatch : StopWatch;
    private positions : CarouselPositions;


    /**
     * Middle of the carousel in height
     */
    private middleHeight : number;
    private buttonOffset : number;

    constructor()
    {
        this.realCarousel = $("#real-carousel");
        this.positions = new CarouselPositions(this.realCarousel);
        this.fillImagesToMeetRequirements();
        this.slides = this.realCarousel.find('#slides');
        this.slidesListItem = this.realCarousel.find('#slides li');
        this.activeSlideSelector = "#real-carousel #slides .active";
        this.activeImageSelector = "#real-carousel #slides .active img";
        this.leftSlideSelector = "#real-carousel #slides .left";
        this.rightSlideSelector = "#real-carousel #slides .right";
        this.lastSlide = this.realCarousel.find("#slides li:last");
        this.firstSlide = this.realCarousel.find("#slides li:first");
        this.nextButton = this.realCarousel.find('#next');
        this.previousButton = this.realCarousel.find('#prev');
        this.captionContainer = this.realCarousel.find("#caption");
        this.captionText = this.captionContainer.find("h1");
        this.moveCompleted = Boolean(true);
        this.speed = 5000;
        this.stopwatch = new StopWatch(() => this.rotateCarousel(), this.speed);
        this.carouselWidth = this.realCarousel.outerWidth();
        this.carouselHeight = this.realCarousel.outerHeight();
        this.item_width = this.carouselWidth / 5;
        this.itemHeight = 0;
        this.activeHeight = 0;
        this.left_value = this.item_width * (-1);
        this.leftSpot = 0;
        this.rightSpot = this.carouselWidth - (this.item_width);
        this.activeWidth = this.carouselWidth / 2;
        this.middleSpot = (this.carouselWidth / 2) - (this.activeWidth / 2);
        this.middleHeight = this.carouselHeight / 2;
        this.buttonOffset = 10;
        this.initialize();
        this.realCarousel.find(".buttons").hover(() => this.stopwatch.pause(), () => this.stopwatch.play());
        //if mouse hover, pause the auto rotation, otherwise rotate it
        this.slides.hover(() => this.stopwatch.pause(), () => this.stopwatch.play());
    }


    /**
     * initializes default values
     */
    private initialize()
    {
        this.addIds();
        this.carouselWidth = this.realCarousel.outerWidth();
        this.carouselHeight = this.realCarousel.outerHeight();
        this.item_width = this.carouselWidth / 5;
        this.leftSpot = 0;
        this.middleSpot = Math.round(this.carouselWidth / 2);
        this.rightSpot = this.carouselWidth - (this.item_width);
        this.activeWidth = this.carouselWidth / 2;
        this.slidesListItem.width(this.item_width + "px")
            .css("left", -1 * this.item_width + "px")
            .css("height", "auto");
        $(this.activeSlideSelector).css("width", this.activeWidth + "px");


        this.middleHeight = this.carouselHeight / 2;
        this.itemHeight = $(this.rightSlideSelector).outerHeight();
        this.activeHeight = $(this.activeSlideSelector).outerHeight();

        this.positions.getMoves().toMiddle($(this.activeSlideSelector));
        this.positions.getMoves().toLeft($(this.leftSlideSelector));
        this.positions.getMoves().toRight($(this.rightSlideSelector));
        this.captionText.html($(this.activeImageSelector).attr("alt"));



        //if user clicked on prev button
        this.previousButton.click(() => this.movePrevious());
        //if user clicked on next button
        this.nextButton.click(() => this.moveNext());
        this.slides.show();


    }

    /**
     * This function duplicates images in order to meet the minimum requirement of 4 images.
     */
    private fillImagesToMeetRequirements()
    {
        let slides = this.realCarousel.find("#slides ul").find("li");
        if (slides.length > 3)
            return;
        let remaingingToFill = 4 - slides.length;
        slides.each((index, elem) =>
        {
            if (remaingingToFill <= 0)
                return false;
            let nextElem = this.getNextElemWrapAround(slides, $(elem));
            nextElem.after($(elem).clone());
            remaingingToFill--;
        })
    }

    /**
     * gets the next element in the collection and wraps to the first if the end is hit
     * @param $collection
     * @param $element
     * @returns {JQuery}
     */
    private getNextElemWrapAround($collection : JQuery, $element : JQuery)
    {
        let nextElem = $element.next();
        if (nextElem.length == 0)
            nextElem = $collection.first();
        return nextElem;
    }

    private addIds()
    {
        this.slidesListItem.each((i, elem) =>
        {
            if (i == 0)
                $(elem).addClass("active");
            if (i == 1)
                $(elem).addClass("right");
            if (i == (this.slidesListItem.length - 1))
                $(elem).addClass("left");
        });
    }

    private movePrevious()
    {
        if (!this.moveCompleted)
            return false;
        this.moveCompleted = false;
        //$("#real-carousel #caption").css("display", "none");
        //set the default item to correct position
        var nextSlide = $(this.leftSlideSelector).prev();
        if (nextSlide.length == 0)
            nextSlide = this.lastSlide;
        //nextSlide.css("left", "0").css("display", "list-item").css("width", "0").css("top", this.middleHeight + "px").css("display", "list-item");
        this.positions.getMoves().toLeftOffScreen(nextSlide);
        this.positions.getAnims().toLeftCarousel(nextSlide);
        this.positions.getAnims().toRightCarousel($(this.activeSlideSelector));
        this.positions.getAnims().toMiddleCarousel($(this.leftSlideSelector));
        this.positions.getAnims().toRightOffScreen($(this.rightSlideSelector));

        this.traverseClassesLeft(nextSlide);
        this.captionContainer.animate({'opacity': 0}, {
            duration: 300, complete: () =>
            {
                this.captionText.html($(this.activeImageSelector).attr("alt"));
                this.captionContainer.animate({'opacity': 1}, {duration: 300});
            }
        });
       setTimeout(() => this.moveCompleted = true, 600);
    }

    /**
     * turns the images to the next one
     */
    private moveNext()
    {
        if(!this.moveCompleted)
            return;
        this.moveCompleted = false;
        //set the default item to correct position
        var nextSlide = $(this.rightSlideSelector).next();
        if (nextSlide.length == 0)
            nextSlide = this.firstSlide;
        this.positions.getMoves().toRightOffScreen(nextSlide);
        this.positions.getAnims().toRightCarousel(nextSlide);
        this.positions.getAnims().toMiddleCarousel($(this.rightSlideSelector));
        this.positions.getAnims().toLeftCarousel($(this.activeSlideSelector));
        this.positions.getAnims().toLeftOffScreen($(this.leftSlideSelector));

        this.traverseClassesRight(nextSlide);

        this.captionContainer.animate({'opacity': 0}, {
            duration: 300, complete: () =>
            {
                this.captionText.html($(this.activeImageSelector).attr("alt"));
                this.captionContainer.animate({'opacity': 1}, {duration: 300});
            }
        });
        setTimeout(() => this.moveCompleted = true, 600);

    }

    protected traverseClassesRight(nextSlide : JQuery)
    {
        var currentRight = $(this.rightSlideSelector);
        var currentActive = $(this.activeSlideSelector);
        var currentLeft = $(this.leftSlideSelector);

        currentRight.removeClass();
        currentRight.addClass("active");
        currentActive.removeClass();
        currentActive.addClass("left");
        currentLeft.removeClass();
        nextSlide.removeClass();
        nextSlide.addClass("right");
    }

    protected traverseClassesLeft(nextSlide : JQuery)
    {
        var currentRight = $(this.rightSlideSelector);
        var currentActive = $(this.activeSlideSelector);
        var currentLeft = $(this.leftSlideSelector);
        currentRight.removeClass();
        currentActive.removeClass();
        currentActive.addClass("right");
        currentLeft.removeClass();
        currentLeft.addClass("active");
        nextSlide.removeClass();
        nextSlide.addClass("left");
    }

    private rotateCarousel()
    {
        this.moveNext();
    }
}


