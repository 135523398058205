import {PositionHandler} from "./PositionHandler";
import {Positions} from "./Positions";
/**
 * Created by Jason on 6/3/2016.
 */
export class MovePositions extends PositionHandler
{
    

    public toRightOffScreen($element : JQuery)
    {
        $element.css({
            left: Positions.rightOff(),
            display: "list-item",
            width: "0",
        });
    }
    public toLeftOffScreen($element : JQuery)
    {
        $element.css({
            left: Positions.leftOff(),
            display: "list-item",
            width: "0",
        });
    }
    public toMiddle($element)
    {
        $element.css({
            left: Positions.middle(),
            display: "list-item",
            width: this.getActiveItemWidth(),
        });
    }
    public toRight($element)
    {
        $element.css({
            left: Positions.right(),
            display: "list-item",
            width: this.getItemWidth(),
        });
    }
    public toLeft($element)
    {
        $element.css({
            left: Positions.left(),
            display: "list-item",
            width: this.getItemWidth(),
        });
    }
}