import {PositionHandler} from "./PositionHandler";
import {Positions} from "./Positions";
/**
 * Created by Jason on 6/3/2016.
 */
export class AnimatePositions extends PositionHandler
{
    public toLeftCarousel($element : JQuery)
    {
        $element.animate({
            left:  Positions.left(),
            width: this.getItemWidth(),
        }, {duration: 600, queue: false});
    }

    public toLeftOffScreen($element : JQuery)
    {
        $element.animate({
            left:  Positions.leftOff(),
            width: 0
        }, {duration: 600, queue: false});

    }

    public toRightOffScreen($element : JQuery)
    {
        $element.animate({
            left:  Positions.rightOff(),
            width: 0
        }, {duration: 600, queue: false});

    }

    public toRightCarousel($element : JQuery)
    {
        //has delay
        $element.animate({
            'left':  Positions.right(),
            'width': this.getItemWidth(),
        }, {duration: 600});
    }
    public toMiddleCarousel($element : JQuery)
    {
        $element.animate({
            'left':  Positions.middle(),
            'width': this.getActiveItemWidth(),
        }, {duration: 600});
    }
}