/**
 * Created by Jason on 6/3/2016.
 */
export class Positions
{
    public static leftOff()
    {
        return "-05%";
    }
    public static left()
    {
        return "10%";
    }
    public static middle()
    {
        return "50%";
    }
    public static right()
    {
        return "90%";
    }
    public static rightOff()
    {
        return "105%";
    }
}