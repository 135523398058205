/**
 * Created by Jason on 6/3/2016.
 */
export class PositionHandler
{
    protected $realCarousel : JQuery
    constructor($realCarousel : JQuery)
    {
        this.$realCarousel = $realCarousel;
    }

    protected getItemWidth()
    {
        return "20%";
    }
    protected getActiveItemWidth()
    {
        return "50%";
    }
}